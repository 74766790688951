


















































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab3UnknownId',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        unknownNumber: null,
        composition: null,
        language: 'en',
      },
      options: [
        {
          text: 'Benzoic acid and benzophenone',
          value: 'benzophenone',
        },
        {
          text: 'Benzoic acid and biphenyl',
          value: 'biphenyl',
        },
      ],
      optionsFr: [
        {
          text: 'Acide benzoïque et benzophénone',
          value: 'benzophenone',
        },
        {
          text: 'Acide benzoïque et biphenyl',
          value: 'biphenyl',
        },
      ],
      questions: [
        {
          en: 'Include your unknown number below.',
          fr: "Specifiez votre numéro d'inconnu ci-dessous.",
        },
        {
          en: 'Based on the analysis with the TLC plates, what is the composition of your unknown?',
          fr: "D'après l'analyse effectuée avec les plaques de CCM, quelle est la composition de votre inconnu ?.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
